<template>
  <div style="background: black;">
    <messenger></messenger>
  </div>
</template>

<script>
import {
  default as Messenger
} from '@/components/widgets/Messenger';
import { mapGetters } from 'vuex';
export default {
  name: 'MessengerPage',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    Messenger
  },
  props: {},
  data () {
    return {
      tree_list: {},
      d_organizationId: '',
      user: {}
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
};

</script>
